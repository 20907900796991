import React from "react";

const Blog = () => {
  return (
    <>
      {/* <!-- ====================================== Section Blogs ===================================== --> */}
      <section className="blog-section" id="blog">
        <div className="heading-container">
          <h2 className="section-heading-text coding-skill-text fade_up">
            Blogs.
          </h2>
          <div className="line"></div>
        </div>
        <div className="blog-main zoom_in">
          <div className="blog-img-section">
            <p className="blog-date">
              20<span>JAN</span>
            </p>
          </div>
          <div>
            <h3 className="post-date">20 January 2024 | Web Development</h3>
            <h4 className="steps">
              Managing Digital Marketing and Websites for Domestic and International Companies
            </h4>
            <h5 className="est">
              Successfully managed digital marketing campaigns and websites for multiple
              companies, both domestic and international. Enhanced online visibility and
              engagement through the use of AI and innovative strategies, covering SEO, content
              management, data analysis, and paid advertising.
            </h5>
            <div className="wrapper blog-btn">
              <a className="btn-hover" href="https://www.linkedin.com/in/yehezkielkristama/">
                Read More
                <svg
                  className="btn-arrow-right"
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                >
                  <path
                    d="M21.5621 14.6665L14.4101 7.51452L16.2957 5.62891L26.6666 15.9999L16.2957 26.3707L14.4101 24.4851L21.5621 17.3332H5.33331V14.6665H21.5621Z"
                    fill="#0F141C"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
        <div className="blog-main zoom_in">
          <div className="blog-img-section blog-img-section2">
            <p className="blog-date">
              15<span>MAR</span>
            </p>
          </div>
          <div>
            <h3 className="post-date">15 March 2024 | Digital Agency</h3>
            <h4 className="steps">
              Overseeing a Digital Agency Serving Diverse Business Sectors (Oura Story)
            </h4>
            <h5 className="est">
              Led a digital agency, developing and executing tailored marketing strategies for clients
              across various sectors. Managed a creative team to ensure effective campaign delivery,
              significantly boosting brand awareness and engagement for our clients through data-driven
              and creative approaches.
            </h5>
            <div className="wrapper blog-btn">
              <a className="btn-hover" href="https://www.linkedin.com/in/yehezkielkristama/">
                Read More
                <svg
                  className="btn-arrow-right"
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                >
                  <path
                    d="M21.5621 14.6665L14.4101 7.51452L16.2957 5.62891L26.6666 15.9999L16.2957 26.3707L14.4101 24.4851L21.5621 17.3332H5.33331V14.6665H21.5621Z"
                    fill="#0F141C"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
        <div className="blog-main mb-0 zoom_in">
          <div className="blog-img-section blog-img-section3">
            <p className="blog-date">
              10<span>APR</span>
            </p>
          </div>
          <div>
            <h3 className="post-date">10 April 2024 | Digital Business Analysis</h3>
            <h4 className="steps">
              Collaborating as a Digital Business Analyst for Renowned Brands
            </h4>
            <h5 className="est">
              Partnered with renowned brands as a Digital Business Analyst, optimizing their marketing
              strategies and improving ROI. Conducted in-depth data analysis to identify new opportunities,
              assess campaign performance, and provide strategic recommendations for business growth.
            </h5>
            <div className="wrapper blog-btn">
              <a className="btn-hover" href="https://www.linkedin.com/in/yehezkielkristama/">
                Read More
                <svg
                  className="btn-arrow-right"
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                >
                  <path
                    d="M21.5621 14.6665L14.4101 7.51452L16.2957 5.62891L26.6666 15.9999L16.2957 26.3707L14.4101 24.4851L21.5621 17.3332H5.33331V14.6665H21.5621Z"
                    fill="#0F141C"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ====================================== Section Blogs End ===================================== --> */}
    </>
  );
};

export default Blog;
