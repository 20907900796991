import React from "react";
import CloseSvg from "../assets/images/svg/colan-icon.svg";
import PopUpImg from "../assets/images/pop-up-img1.jpg";
import PopUpImg2 from "../assets/images/pop-up-img2.jpg";

const Popup = ({ isVisible, onClose }) => {
  return (
    <div className={`popup-wrap ${isVisible ? "visible" : ""}`}>
      <div className="popup-box transform-in">
        <img className="pop-up-img1" src={PopUpImg} alt="pop-up-img1" />
        <a className="close-btn popup-close" href="#" onClick={onClose}>
          <svg
            width="40px"
            height="40px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g strokeWidth="0"></g>
            <g strokeLinecap="round" strokeLinejoin="round"></g>
            <g>
              <path
                d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                stroke="#FFF"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M9.16998 14.83L14.83 9.17004"
                stroke="#FFF"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M14.83 14.83L9.16998 9.17004"
                stroke="#FFF"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </g>
          </svg>{" "}
        </a>
        <p className="corpo">Business Development Project</p> {/* Mengubah judul */}
        <p className="corpo-sub">Project Details</p> {/* Mengubah subjudul */}
        <div className="corporate_main">
          <div className="cuberto_main">
            <p className="created_by">Created By:</p>
            <p className="cuberto">Yehezkiel Kristama</p> {/* Mengubah pembuat */}
          </div>
          <div className="cuberto_main">
            <p className="created_by">Date:</p>
            <p className="cuberto">20/03/2024</p> {/* Mengubah tanggal */}
          </div>
          <div className="cuberto_main">
            <p className="created_by">Client:</p>
            <p className="cuberto">PT. Urban Plastik Indonesia</p> {/* Mengubah klien */}
          </div>
          <div className="cuberto_main">
            <p className="created_by">Categories:</p>
            <p className="cuberto">Business Development</p> {/* Mengubah kategori */}
          </div>
        </div>
        <p className="Ornare">
          Successfully managed and executed business development strategies that 
          significantly increased company growth and market reach. 
          Leveraged AI technology and innovative methods to enhance 
          overall performance and efficiency.
        </p> {/* Mengubah deskripsi */}
        <div className="pop-up-img-section2">
          <img src={PopUpImg2} alt="pop-up-img2" />
        </div>
        <p className="Ornare p-0">
          Led cross-functional teams to achieve project milestones and deliverables. 
          Established strong client relationships and ensured customer satisfaction 
          through continuous improvement and strategic planning.
        </p> {/* Mengubah deskripsi */}
      </div>
    </div>
  );
};

export default Popup;
