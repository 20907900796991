import React from "react";
import BasicPlanSvg from "../assets/images/svg/basic-plain.svg";
import StandardPlanSvg from "../assets/images/svg/strandard-plan.svg";
import GoldPlanSvg from "../assets/images/svg/gold-plan.svg";
import ColanIcon from "../assets/images/svg/colan-icon.svg";
import Slider from "react-slick";
import "../../node_modules/slick-carousel/slick/slick.css";
import "../../node_modules/slick-carousel/slick/slick-theme.css";

const Pricing = () => {
  const settings = {
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    dots: true,
    speed: 1000,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          arrows: false,
        },
      },
    ],
  };
  return (
    <>
      {/* <!-- ====================================== Section Pricing ===================================== --> */}
      <section className="Pricing-section" id="pricing">
        <div className="heading-container">
          <h2 className="section-heading-text coding-skill-text fade_up">
            Pricing.
          </h2>
          <div className="line"></div>
        </div>
        <div className="pricing-section-main zoom_in">
          <div className="basic-plain-box-main">
            <div className="basic-plain-box">
              <img src={BasicPlanSvg} alt="basic-plain" />
              <p className="basic-plain-text">UMKM (Small Businesses)</p>
            </div>
            <div className="basic-plain-box-sub">
              <p className="mon">
                100<sup>$</sup>
                <span>/mon</span>
              </p>
            </div>
          </div>
          <div className="development-box">
            <div>
              <h3 className="pricing-plaines-name">Social Media Engagement</h3>
              <h3 className="pricing-plaines-name">Content Creation</h3>
              <h3 className="pricing-plaines-name">Graphic Design</h3>
              <h3 className="pricing-plaines-name">Campaign Management</h3>
              <h3 className="pricing-plaines-name">Analytics & Reporting</h3>
            </div>
            <div className="choose-plain-btn-main">
              <div className="wrapper">
                <a className="btn-hover" href="https://www.instagram.com/oura.story/">
                  Choose Plan
                </a>
              </div>
              <p className="taxes">11% taxes will include after make price</p>
            </div>
          </div>
        </div>
        <div className="pricing-section-main zoom_in">
          <div className="basic-plain-box-main">
            <div className="basic-plain-box basic-plain-box2">
              <img src={StandardPlanSvg} alt="strandard-plan" />
              <p className="basic-plain-text">Scale Up</p>
            </div>
            <div className="basic-plain-box-sub">
              <p className="mon">
                300<sup>$</sup>
                <span>/mon</span>
              </p>
            </div>
          </div>
          <div className="development-box">
            <div>
              <h3 className="pricing-plaines-name">Social Media Strategy</h3>
              <h3 className="pricing-plaines-name">Social Media Engagement</h3>
              <h3 className="pricing-plaines-name">Content Creation</h3>
              <h3 className="pricing-plaines-name">Graphic Design</h3>
              <h3 className="pricing-plaines-name">Campaign Management</h3>
              <h3 className="pricing-plaines-name">Analytics & Reporting</h3>
            </div>
            <div className="choose-plain-btn-main">
              <div className="wrapper">
                <a className="btn-hover" href="https://www.instagram.com/oura.story/">
                  Choose Plan
                </a>
              </div>
              <p className="taxes">11% taxes will include after make price</p>
            </div>
          </div>
        </div>
        <div className="pricing-section-main mb-0 zoom_in">
          <div className="basic-plain-box-main">
            <div className="basic-plain-box basic-plain-box3">
              <img src={GoldPlanSvg} alt="gold-plan" />
              <p className="basic-plain-text">Corporate</p>
            </div>
            <div className="basic-plain-box-sub">
              <p className="mon">
                550<sup>$</sup>
                <span>/mon</span>
              </p>
            </div>
          </div>
          <div className="development-box">
            <div>
              <h3 className="pricing-plaines-name">Comprehensive Social Media Strategy</h3>
              <h3 className="pricing-plaines-name">Social Media Engagement</h3>
              <h3 className="pricing-plaines-name">Advanced Content Creation</h3>
              <h3 className="pricing-plaines-name">Professional Graphic Design</h3>
              <h3 className="pricing-plaines-name">Campaign Management</h3>
              <h3 className="pricing-plaines-name">Detailed Analytics & Reporting</h3>
            </div>
            <div className="choose-plain-btn-main">
              <div className="wrapper">
                <a className="btn-hover" href="https://www.instagram.com/oura.story/">
                  Choose Plan
                </a>
              </div>
              <p className="taxes">11% taxes will include after make price</p>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ====================================== Section Pricing End ===================================== --> */}
      {/* <!-- ====================================== Section Testimonials ===================================== --> */}
      <section className="testimonials-section">
        <div className="heading-container">
          <h2 className="section-heading-text coding-skill-text fade_up">
            Testimonials.
          </h2>
          <div className="line"></div>
        </div>
        <p className="testament fade_up">
          Their words are a testament to our commitment to excellence and our
          ability to bring their visions to life. Read on to see how we've made
          a lasting impact on their brands and projects.
        </p>

        <Slider className="Testimonials" {...settings}>
          <div>
            <div className="colan-icon-img-main">
              <img src={ColanIcon} alt="colan-icon" />
            </div>
            <p className="avix">
              Agnes Lidia has been instrumental in elevating our brand's online
              presence. Her insights and strategies have driven our social media engagement to new heights. We couldn't be happier with the results.
            </p>
            <p className="client-name">Agnes Lidia</p>
            <p className="kello">Baligeotex</p>
          </div>
          <div>
            <div className="colan-icon-img-main">
              <img src={ColanIcon} alt="colan-icon" />
            </div>
            <p className="avix">
              Working with Devani Amanda has been a transformative experience for our company's digital marketing. Her innovative ideas and dedication have significantly boosted our online presence.
            </p>
            <p className="client-name">Devani Amanda</p>
            <p className="kello">Social Media Specialist</p>
          </div>
          <div>
            <div className="colan-icon-img-main">
              <img src={ColanIcon} alt="colan-icon" />
            </div>
            <p className="avix">
              Anna Erisa Utami's expertise in marketing has helped us reach new markets and increase our revenue. Her strategic approach and attention to detail are commendable.
            </p>
            <p className="client-name">Anna Erisa Utami</p>
            <p className="kello">Marketing of Urban Plastic Indonesia</p>
          </div>
          <div>
            <div className="colan-icon-img-main">
              <img src={ColanIcon} alt="colan-icon" />
            </div>
            <p className="avix">
              Yehezkiel's skills in digital marketing and website development are unmatched. His strategies have significantly boosted our online presence and business growth. He is a visionary in business development.
            </p>
            <p className="client-name">Ratna Amir</p>
            <p className="kello">Director Managing PT. Urban Plastik Indonesia</p>
          </div>
          <div>
            <div className="colan-icon-img-main">
              <img src={ColanIcon} alt="colan-icon" />
            </div>
            <p className="avix">
              Yehezkiel is responsible for planning long-term strategies for the company's growth and development. He identifies new market opportunities and crafts innovative business plans to ensure the company remains competitive and grow.
            </p>
            <p className="client-name">Muhammad Lutfi Zunnur</p>
            <p className="kello">Universitas Gadjah Mada (UGM)</p>
          </div>
        </Slider>
      </section>
      {/* <!-- ====================================== Section Testimonials End ===================================== --> */}
    </>
  );
};
export default Pricing;
