import React from "react";
import DigitalMarketingSvg from "../assets/images/svg/ui-ux.svg"; // Assuming you replace the image for Digital Marketing
import DownArrow from "../assets/images/svg/down-arrow.svg";
import VisualBranding from "../assets/images/svg/visual-branding.svg";
import WebDevelopement from "../assets/images/svg/web-development.svg";
import SeoSemSvg from "../assets/images/svg/app-development.svg"; // Assuming you replace the image for SEO/SEM
import GalleryImg2 from "../assets/images/gallery/gallery-img2.jpg";
import { Link } from "react-router-dom";

const Services = () => {
  return (
    <>
      {/* <!-- ====================================== Section Services ===================================== --> */}
      <section className="Services-section" id="services">
        <div className="heading-container">
          <h2 className="section-heading-text coding-skill-text fade_up">
            Services.
          </h2>
          <div className="line"></div>
        </div>
        <h3 className="services-product-text fade_up">
          BUILDING DIGITAL PRODUCT WITH BETTER EXPERIENCE
        </h3>
        <p className="visoion-text fade_up">
          Our vision is to be a trailblazing force in the world of web design
          and development, recognized for our unwavering commitment to
          excellence, integrity, and customer satisfaction.
        </p>
        <div className="row services-row">
          <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-6 services-column fade_up">
            <Link to="#services-popup">
              <div className="services-img">
                <img src={DigitalMarketingSvg} alt="digital-marketing" />
              </div>
              <p className="services-text">DIGITAL MARKETING</p>
              <p className="molestie">
                Enhance your online presence with tailored marketing strategies.
                Drive engagement and conversions through targeted campaigns.
              </p>
              <img
                className="down-arrow-svg"
                src={DownArrow}
                alt="down-arrow"
              />
            </Link>
          </div>
          <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-6 services-column fade_up">
            <Link to="#services-popup">
              <div className="services-img services-img2">
                <img src={VisualBranding} alt="visual-branding" />
              </div>
              <p className="services-text services-text2">VISUAL BRANDING</p>
              <p className="molestie">
                Create a strong brand identity with compelling visuals.
                Stand out with unique and memorable branding.
              </p>
              <img
                className="down-arrow-svg"
                src={DownArrow}
                alt="down-arrow"
              />
            </Link>
          </div>
          <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-6 services-column fade_up">
            <Link to="#services-popup">
              <div className="services-img services-img3">
                <img src={WebDevelopement} alt="web-development" />
              </div>
              <p className="services-text services-text3">WEB DEVELOPMENT</p>
              <p className="molestie">
                Build responsive and user-friendly websites.
                Ensure optimal performance and scalability.
              </p>
              <img
                className="down-arrow-svg"
                src={DownArrow}
                alt="down-arrow"
              />
            </Link>
          </div>
          <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-6 services-column fade_up">
            <Link to="#services-popup">
              <div className="services-img services-img4">
                <img src={SeoSemSvg} alt="seo-sem" />
              </div>
              <p className="services-text services-text4">SEO/SEM</p>
              <p className="molestie">
                Improve your search engine rankings with SEO.
                Maximize your reach with effective SEM campaigns.
              </p>
              <img
                className="down-arrow-svg"
                src={DownArrow}
                alt="down-arrow"
              />
            </Link>
          </div>
        </div>
      </section>
      {/* <!-- ====================================== Section Services End ===================================== --> */}
      {/* <!-- ====================================== Section Services Pop Up ===================================== --> */}
      <div id="services-popup" className="popup-container">
        <div className="popup-content popup-content2">
          <Link to="#" className="close">
            &times;
          </Link>
          <h2 className="simple-steps">DIGITAL MARKETING</h2>
          <img
            className="blog-popup-img1"
            src={GalleryImg2}
            alt="gallery-img8"
          />
          <p className="blog-sit-text">
            Digital marketing encompasses all marketing efforts that use an
            electronic device or the internet. Businesses leverage digital
            channels such as search engines, social media, email, and other
            websites to connect with current and prospective customers.
          </p>
          <p className="blog-sit-text">
            From your website itself to your online branding assets - digital
            advertising, email marketing, online brochures, and beyond - there’s
            a huge spectrum of tactics and assets that fall under the umbrella
            of digital marketing.
          </p>
          <h2 className="simple-steps services-popup-text">What Is Digital Marketing?</h2>
          <p className="blog-sit-text">
            Digital marketing is defined by the use of numerous digital tactics
            and channels to connect with customers where they spend much of
            their time: online. The best digital marketers have a clear picture
            of how each digital marketing campaign supports their overarching
            goals.
          </p>
          <p className="blog-sit-text">
            Examples of digital marketing include SEO, content marketing, social
            media marketing, pay-per-click (PPC), affiliate marketing, and email
            marketing.
          </p>
        </div>
      </div>
      {/* <!-- ====================================== Services PopUp End ===================================== --> */}
    </>
  );
};
export default Services;
